@font-face {
  font-family: 'ArimaMadurai';
  src: local('ArimaMadurai'), url(../fonts/ArimaMadurai/ArimaMadurai-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'ArimaMadurai';
  src: local('ArimaMadurai'), url(../fonts/ArimaMadurai/ArimaMadurai-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'ArimaMadurai';
  src: local('ArimaMadurai'), url(../fonts/ArimaMadurai/ArimaMadurai-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'ArimaMadurai';
  src: local('ArimaMadurai'), url(../fonts/ArimaMadurai/ArimaMadurai-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'ArimaMadurai';
  src: local('ArimaMadurai'), url(../fonts/ArimaMadurai/ArimaMadurai-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'ArimaMadurai';
  src: local('ArimaMadurai'), url(../fonts/ArimaMadurai/ArimaMadurai-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'ArimaMadurai';
  src: local('ArimaMadurai'), url(../fonts/ArimaMadurai/ArimaMadurai-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'ArimaMadurai';
  src: local('ArimaMadurai'), url(../fonts/ArimaMadurai/ArimaMadurai-Thin.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Quando';
  src: local('Quando'), url(../fonts/Quando/Quando-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-ExtraBoldItalic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-ExtraLightItalic.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-Thin.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-BoldItalic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-ExtraLightItalic.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-Italic.ttf) format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Titillium';
  src: local('Titillium'), url(../fonts/Titillium/TitilliumWeb-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

body {
  font-family: Titillium, Roboto, Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: Raleway, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0.5rem;

  &.quote {
    color: var(--aac-blue);
    font-family: ArimaMadurai, Titillium, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
    text-transform: none;
  }
}

.card-header {
  font-family: Raleway, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
