.hide {
  display: none;
}

.display {
  display: block;
}

/********** NAVBAR **********/

/********** Dropdown Menu **********/
.dropdown {
  a {
    &.nav-link {
      color: var(--mdb-white);

      &:hover {
        color: var(--mdb-gray-400)
      }
    }
  }
}

div.dropdown-menu {
  margin-top: 10px;
  border-radius: 0.2rem;

  a {
    color: var(--mdb-white);

    &.hover {
      color: var(--aac-blue);
    }

    &.no-hover {
      color: var(--mdb-white);
    }

    //background-color: var(--aac-blue);
    /*border-left: 1px solid var(--mdb-white);
    border-top: 1px solid var(--mdb-white);
    border-right: 1px solid var(--mdb-white); */

    &:first-child {
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;

      a.dropdown-item {
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
      }
    }

    &:last-child {
      border-bottom-left-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;

      a.dropdown-item {
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
    }

    &:hover {
      color: var(--aac-blue);
    }
  }
}

/********** LAYOUT **********/

/********** Containers **********/
.grey-container, .grey-row {
  background-color: var(--mdb-gray-300);
}

.blue-container {
  background-color: var(--aac-blue);
  color: var(--mdb-white);
}

/********** Card **********/
.card {
  img {
    object-fit: cover;
  }

  .card-link {
    color: var(--aac-blue);
  }
}

h4 {
  padding-top: 20px;
}

p {
  margin-bottom: 1rem;
}

.vertical-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.vertical-title {
  border-right: 3px solid #C8C8C8;

  div {
    font-size: 53px;
    font-weight: bolder;
    text-orientation: upright;
    text-transform: uppercase;
    writing-mode: vertical-rl;
  }
}

#service-icons {
  img {
    height: 75px;
  }
}

.info-only {
  .address {
    p {
      margin-bottom: 0;
    }

    .contact-info {
      padding-top: 20px;
      color: var(--aac-blue);
    }
  }
}